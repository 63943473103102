import React, { useState, useEffect } from "react";
import "./styles.scss";
import { ProgressIndicator } from "../ProgressIndicator";
import { Spacer, StyleProvider, Text, ThemePicker } from "vcc-ui";
import { Icon } from "@volvo-cars/react-icons";
var bookingOptions = [
    { name: "Bildeve", tenantKey: "02173f2a-d0fe-4144-9078-0afd077d4c24" },
    { name: "Nybergs Bil", tenantKey: "8768672c-88dd-42bf-b204-9af4922e9f51" },
    { name: "Bilbolaget", tenantKey: "70aa0c40-5007-43c6-b201-829b17c5f908" },
    {
        name: "Visby motorcentral",
        tenantKey: "b2caf27a-ff31-46fb-912c-46b15f8afc51",
    },
];
var Stenskott = function () {
    var _a = useState(null), selectedOption = _a[0], setSelectedOption = _a[1];
    var _b = useState(false), showBookingModule = _b[0], setShowBookingModule = _b[1];
    useEffect(function () {
        if (!document.querySelector('script[src="https://ecom-cdn.tacdis.com/ecom-retailer-booking/production/js/ecom-retailer-booking.umd.js"]')) {
            var script = document.createElement("script");
            script.src =
                "https://ecom-cdn.tacdis.com/ecom-retailer-booking/production/js/ecom-retailer-booking.umd.js";
            script.type = "text/javascript";
            script.async = true;
            document.head.appendChild(script);
        }
    }, []);
    var handleSelectOption = function (option) {
        setSelectedOption(option);
        setShowBookingModule(true);
    };
    var handleBack = function () {
        setShowBookingModule(false);
        setSelectedOption(null);
    };
    return (React.createElement(StyleProvider, null,
        React.createElement(ThemePicker, { variant: "light" },
            showBookingModule && (React.createElement("div", { className: "back-btn", onClick: handleBack },
                React.createElement(Icon, { alt: "left", icon: "chevron-back", size: 24 }),
                React.createElement(Text, { variant: "bates", subStyle: "inline-link" }, "Tillbaka till listan"))),
            React.createElement(ProgressIndicator, { displayPage: "iframe" }),
            React.createElement(Spacer, null),
            React.createElement("div", null, showBookingModule ? (React.createElement("div", { className: "booking-module flex justify-center" },
                React.createElement("div", { id: "booking-module-container" }, selectedOption && (React.createElement("ecom-retailer-booking", { key: selectedOption.tenantKey, services: "windscreenchiprepair", apikey: selectedOption.tenantKey }))))) : (React.createElement("div", { className: "container-xl" },
                React.createElement("div", { className: " grid grid-cols-2 gap-16" }, bookingOptions.map(function (option) { return (React.createElement("div", { key: option.tenantKey, style: {
                        boxShadow: "none",
                        transform: "none",
                        border: "1px solid #00000070",
                        borderRadius: "4px",
                        backgroundColor: "#fafafa",
                        height: "70px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }, className: "booking-card", onClick: function () { return handleSelectOption(option); } },
                    React.createElement("p", null, option.name))); }))))))));
};
export default Stenskott;
