import React, { useState, useEffect } from "react";
import "./styles.scss";
export default function (props) {
    var _a = useState(""), service = _a[0], setService = _a[1];
    useEffect(function () {
        props.onSelectService("sb");
    }, [service]);
    var ServiceCard = function (_a) {
        var onClick = _a.onClick, tabIndex = _a.tabIndex, label = _a.label;
        return (React.createElement("div", { className: "service-card", role: "button", tabIndex: tabIndex, onClick: onClick, onKeyDown: function (e) {
                if (e.key === "Enter") {
                    onClick();
                }
            } },
            React.createElement("div", null,
                React.createElement("p", { className: "font-medium" }, label))));
    };
    return (React.createElement("div", { className: "services-container" }, props.retailer.hasWheelChange ||
        props.retailer.hasServiceBooking ||
        props.retailer.hasWindowRepair ? (React.createElement(React.Fragment, null,
        props.retailer.hasServiceBooking && (React.createElement(ServiceCard, { onClick: function () { return setService("sb"); }, tabIndex: 1, label: "Boka Service" })),
        props.retailer.hasWheelChange && (React.createElement(ServiceCard, { onClick: function () { return setService("wc"); }, tabIndex: 2, label: "Boka D\u00E4ckbyte" })),
        props.retailer.hasWindowRepair && (React.createElement(ServiceCard, { onClick: function () { return setService("wr"); }, tabIndex: 3, label: "Boka F\u00F6nsterreparation" })))) : (React.createElement("p", null,
        "Finns tyv\u00E4rr inga tj\u00E4nster hos: ",
        props.retailer.id))));
}
