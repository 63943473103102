import React, { useEffect, useRef } from "react";
import { Icon } from "@volvo-cars/react-icons";
import "./styles.scss";
export default function (props) {
    var theIframe = useRef(null);
    var onWindowMessage = function (e) {
        if (typeof e.data === "string") {
            if (e.data.length > 25) {
                if (e.data.substring(0, 25) === "vcs-tacdis-iframe-height:") {
                    var theHeight = Math.round(parseFloat(e.data.substring(25)));
                    if (theIframe && theIframe.current) {
                        theIframe.current.style.height = theHeight + 20 + "px";
                    }
                }
            }
        }
    };
    useEffect(function () {
        window.addEventListener("message", onWindowMessage, false);
        return function () {
            window.removeEventListener("message", onWindowMessage);
        };
    }, []);
    var params = new URLSearchParams();
    params.append("target", window.location.href);
    params.append("retailer", props.retailer.id);
    var url = null;
    if (props.serviceId === "wc") {
        url = props.endpoint + "/wheelchange.php?" + params.toString();
    }
    else if (props.serviceId === "sb") {
        url = props.endpoint + "/servicebooking.php?" + params.toString();
    }
    else if (props.serviceId === "wr") {
        url = props.endpoint + "/windowrepair.php?" + params.toString();
    }
    return (React.createElement("div", { className: "tacdisIframeComponent" },
        React.createElement("div", { style: { backgroundColor: "#fafafa" }, className: "w-full pt-64 pb-64 text-center" },
            React.createElement("h1", { className: "heading-1 text-secondary" }, props.retailer.branchName)),
        React.createElement("div", { style: {
                borderBottom: "1px solid #0000001F",
                borderTop: "1px solid #0000001F",
                marginBottom: "-9px",
            }, className: "w-full" },
            React.createElement("div", { className: "pl-24 pt-16 pb-16", style: {
                    maxWidth: "calc(1400px + 58px)",
                    marginInline: "auto",
                } },
                React.createElement("div", { className: "flex flex-col md:flex-row items-start md:items-center justify-start gap-8" },
                    React.createElement("div", { className: "flex gap-8" },
                        React.createElement(Icon, { icon: "arrow-back", color: "secondary", size: 24, alt: "Tillbaka till listan" }),
                        React.createElement("p", { className: "font-medium mr-32", style: {
                                color: "#00000070",
                                cursor: "pointer",
                            }, onClick: function () {
                                props.onBack();
                            } }, "Tillbaka till listan")),
                    React.createElement("p", { className: "font-medium text-secondary hidden md:block" },
                        props.serviceType,
                        " hos ",
                        props.retailer.branchName)))),
        url !== null && (React.createElement("iframe", { ref: theIframe, src: url, seamless: true }))));
}
