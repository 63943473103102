var _a, _b, _c, _d, _e, _f;
import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Route, Routes, useLocation } from "react-router-dom";
import App from "./components/App";
import WindowRepair from "./components/WindowRepair";
import TyreService from "./components/TyreService";
import Stenskott from "./components/Stenskott";
import { TopMenu } from "./components/TopMenu";
import { PageTrackingProvider } from "@volvo-cars/tracking";
var AppWithTracking = function (_a) {
    var apiEndpoint = _a.apiEndpoint, iframeEndpoint = _a.iframeEndpoint, retailerId = _a.retailerId, serviceId = _a.serviceId;
    var location = useLocation();
    var getPageName = function () {
        switch (location.pathname) {
            case "/":
                return "book a service";
            case "/glasreparation":
                return "book a window repair";
            case "/hjulskifte":
                return "book a tyre Service";
            case "/stenskott":
                return "book a windscreen chip repair";
            default:
                return "Unknown Page";
        }
    };
    return (React.createElement(PageTrackingProvider, { event: "page_view", page_name: getPageName(), page_type: "local page", country_code: "se", market_language: "sv-se" },
        React.createElement(TopMenu, null),
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: React.createElement(App
                /*apiEndpoint={apiEndpoint}
                iframeEndpoint={iframeEndpoint}
                preSelectRetailer={retailerId}
                preSelectService={serviceId}*/
                , null) }),
            React.createElement(Route, { path: "/glasreparation", element: React.createElement(WindowRepair, null) }),
            React.createElement(Route, { path: "/hjulskifte", element: React.createElement(TyreService, null) }),
            React.createElement(Route, { path: "/stenskott", element: React.createElement(Stenskott, null) }))));
};
// Find elements to inject the app into
var className = "fbi-tacdis-iframe-target";
var foundElements = document.getElementsByClassName(className);
console.log("Found ".concat(foundElements.length, " target elements with class name: ").concat(className));
var _loop_1 = function (i) {
    var elem = foundElements.item(i);
    if (!elem)
        return "continue";
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var apiEndpoint = (_a = elem.getAttribute("data-api-endpoint")) !== null && _a !== void 0 ? _a : "";
    var iframeEndpoint = (_b = elem.getAttribute("data-iframe-endpoint")) !== null && _b !== void 0 ? _b : "";
    var retailerId = (_d = (_c = urlParams.get("retailerId")) !== null && _c !== void 0 ? _c : elem.getAttribute("data-retailer-id")) !== null && _d !== void 0 ? _d : "";
    var serviceId = (_f = (_e = urlParams.get("serviceId")) !== null && _e !== void 0 ? _e : elem.getAttribute("data-service-id")) !== null && _f !== void 0 ? _f : "";
    var root = ReactDOM.createRoot(elem);
    var renderApp = function () {
        root.render(React.createElement(HashRouter, { basename: "/", future: {
                v7_relativeSplatPath: true,
            } },
            React.createElement(AppWithTracking, { apiEndpoint: apiEndpoint, iframeEndpoint: iframeEndpoint, retailerId: retailerId, serviceId: serviceId })));
    };
    renderApp();
    // Enable HMR
    if (import.meta.webpackHot) {
        import.meta.webpackHot.accept("./components/App", function () {
            console.log("HMR: Reloading App");
            renderApp();
        });
    }
};
for (var i = 0; i < foundElements.length; i++) {
    _loop_1(i);
}
