import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@volvo-cars/react-icons";
export var TopMenu = function () {
    var location = useLocation();
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var handleClick = function () {
        if (window.innerWidth < 480) {
            setIsOpen(function (prev) { return !prev; });
        }
    };
    var handleClose = function () {
        setIsOpen(false);
    };
    var getPageName = function () {
        switch (location.pathname) {
            case "/":
                return "Boka service";
            case "/glasreparation":
                return "Boka glasreparation";
            case "/hjulskifte":
                return "Boka hjulskifte";
            default:
                return "Meny";
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("nav", { className: "container-xl relative start-0\n            pl-24\n            pr-24\n            md:pl-0\n            md:pr-0\n            md:pt-8 \n            md:pb-8 \n            pt-16 \n            pb-16", "data-bleed": "until-md", onClick: handleClick },
            React.createElement("div", { className: "md:hidden flex justify-between items-center" },
                React.createElement("p", { className: "font-medium" }, getPageName()),
                React.createElement(Icon, { icon: isOpen ? "chevron-up" : "chevron-down", color: "secondary", size: 24, alt: "v\u00E4lj \u00E5terf\u00F6rs\u00E4ljare" }))),
        React.createElement("ul", { className: "w-full absolute pl-0 flex-row gap-16 md:gap-32 pt-4 ".concat(isOpen
                ? "start-0 w-full bg-always-white absolute flex flex-col px-24 py-16"
                : "hidden", " md:flex"), style: { zIndex: 99, backgroundColor: "#fff" } },
            React.createElement("span", { className: "container-xl flex flex-col md:flex-row gap-16 md:gap-32" }, [
                { path: "/", label: "Boka service" },
                { path: "/hjulskifte", label: "Boka hjulskifte" },
                {
                    path: "/glasreparation",
                    label: "Boka glasreparation",
                },
            ].map(function (_a) {
                var path = _a.path, label = _a.label;
                return (React.createElement(Link, { className: "font-medium pb-4 md:pb-8", to: path, key: path, onClick: handleClose },
                    React.createElement("li", { className: "pb-8 w-fit", style: {
                            borderBottom: location.pathname === path
                                ? "2px solid #000"
                                : "none",
                        } }, label)));
            })))));
};
