import React, { useEffect, useState } from "react";
import { TextInput } from "@volvo-cars/react-forms";
import { addCustomRetailers } from "../../helpers/addCustomRetailers";
import { Icon } from "@volvo-cars/react-icons";
import { Link } from "react-router-dom";
import { useTracker } from "@volvo-cars/tracking";
import "./styles.scss";
export default function (props) {
    var _a, _b, _c, _d, _e, _f;
    var _g = useState(""), retailer = _g[0], setRetailer = _g[1];
    var _h = useState(""), retailerFilter = _h[0], setRetailerFilter = _h[1];
    var allRetailers = addCustomRetailers(props.retailers);
    var tracker = useTracker();
    useEffect(function () {
        window.scrollTo(0, 0);
        if (retailer !== "") {
            props.onSelectRetailer(retailer);
        }
    }, [retailer]);
    var filterRetailers = function (retailer) {
        var regex = new RegExp(retailerFilter.toLowerCase(), "g");
        var branchNameMatch = regex.test(retailer.branchName.toLowerCase());
        // console.log(Array.isArray(retailer.townNames));
        var townNameMatch = Array.isArray(retailer === null || retailer === void 0 ? void 0 : retailer.townNames)
            ? retailer.townNames.some(function (town) { return regex.test(town.toLowerCase()); })
            : false;
        return branchNameMatch || townNameMatch;
    };
    var isMobile = window.innerWidth < 481;
    return (React.createElement("div", { className: "retailers-container" },
        React.createElement("div", { className: "relative mb-24 md:mb-48 hero" },
            React.createElement("img", { src: isMobile ? props.mobileImage : props.image, style: {
                    width: "100%",
                    lineHeight: 0,
                    objectFit: "cover",
                    height: "100%",
                    objectPosition: props.imagePosition,
                } }),
            React.createElement("div", { className: "pt-32 pb-24 p-24 md:p-24 lg:p-0 h-full md:h-fit flex flex-col justify-between", style: {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "100%",
                    maxWidth: "569px",
                } },
                React.createElement("div", { className: "relative" },
                    React.createElement("h1", { className: "heading-1 text-always-white pb-4 text-center" }, props.title),
                    React.createElement("p", { className: "font-16 md:font-20 text-always-white pt-8 pb-32 text-center" }, props.description),
                    React.createElement("div", { id: "scroll-target", style: {
                            position: "absolute",
                            bottom: "30px",
                            width: "1px",
                            height: "1px",
                            visibility: "hidden",
                        } })),
                React.createElement(TextInput, { value: retailerFilter, className: "search-retailers", tabIndex: 1, label: "Filtrera p\u00E5 Volvohandlare eller ort", name: "search-retailers", onChange: function (e) {
                        return setRetailerFilter(e.target.value);
                    }, onFocus: function (e) {
                        // scroll to top of element
                        var target = document.getElementById("scroll-target");
                        if (typeof window !== "undefined" &&
                            window.innerWidth < 481) {
                            target.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                                inline: "nearest",
                            });
                            setTimeout(function () {
                                target.scrollIntoView({
                                    behavior: "smooth",
                                    block: "start",
                                });
                            }, 100);
                        }
                    }, onKeyDown: function (e) {
                        return e.key === "Enter" &&
                            e.target.blur();
                    } }))),
        React.createElement("div", { className: "container-xl grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-24 retailer-container", style: { gridAutoRows: isMobile ? "" : "1fr" } }, allRetailers.filter(filterRetailers).map(function (retailer, idx) {
            var _a, _b, _c;
            // Hide custom retailers based on service type
            if (props.type === "windowrepair" &&
                ((_a = retailer.hideOnServices) === null || _a === void 0 ? void 0 : _a.includes("window"))) {
                return null;
            }
            else if (props.type === "wheelchange" &&
                ((_b = retailer.hideOnServices) === null || _b === void 0 ? void 0 : _b.includes("tyre"))) {
                return null;
            }
            else if (props.type === "service" &&
                ((_c = retailer.hideOnServices) === null || _c === void 0 ? void 0 : _c.includes("service"))) {
                return null;
            }
            return React.createElement("a", { key: retailer.id, className: "retailer-card", href: (retailer === null || retailer === void 0 ? void 0 : retailer.redirect) && props.type === "windowrepair"
                    ? retailer.urlWindowRepair
                    : props.type === "wheelchange"
                        ? retailer.urlWheelChange
                        : retailer.url, style: {
                    textDecoration: "none",
                    color: "inherit",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                }, onClick: function (e) {
                    tracker.customEvent({
                        eventCategory: "retailer card",
                        eventAction: "button|click",
                        eventLabel: retailer.branchName ? retailer.branchName : "retailer",
                    });
                    if (!retailer.redirect &&
                        retailer.branchName !== "Bilia") {
                        e.preventDefault();
                        setRetailer(retailer.id);
                    }
                }, onKeyDown: function (e) {
                    if (e.key === "Enter" &&
                        !retailer.redirect &&
                        retailer.branchName !== "Bilia") {
                        e.preventDefault();
                        setRetailer(retailer.id);
                    }
                } },
                React.createElement("div", { className: "p-16 md:p-24", style: {
                        position: "relative",
                        boxShadow: "none",
                        transform: "none",
                        cursor: "pointer",
                        flex: "1",
                    }, tabIndex: idx + 1 },
                    React.createElement("div", { className: "flex justify-between items-center" },
                        React.createElement("p", { className: "font-medium md:font-20 mb-4" }, retailer.branchName),
                        React.createElement(Icon, { icon: "arrow-forward", color: "secondary", size: 24, alt: "v\u00E4lj \u00E5terf\u00F6rs\u00E4ljare" })),
                    retailer.showTownNames && (React.createElement("p", { className: "text-secondary font-14 md:font-16" }, Array.isArray(retailer === null || retailer === void 0 ? void 0 : retailer.townNames) &&
                        retailer.townNames.join(", "))),
                    retailer.description && (React.createElement("p", { className: "text-secondary" }, retailer.description))));
        })),
        allRetailers.filter(filterRetailers).length === 0 && (React.createElement("div", { className: "no-retailers", style: {
                background: "#fafafa",
                padding: "34px",
                maxWidth: "400px",
                margin: "auto",
            } },
            React.createElement("p", { className: "font-medium text-center" }, "Inga \u00E5terf\u00F6rs\u00E4ljare eller orter matchade din s\u00F6kning. Kontrollera stavningen eller prova ett annat s\u00F6kord."))),
        props.banner && (React.createElement("div", { className: "relative container-xl pt-64 mt-0 md:mt-16 h-full" },
            React.createElement("div", { className: "relative banner" },
                React.createElement("div", { className: "pl-64 ml-48", style: {
                        position: "absolute",
                        top: "50%",
                        left: "0",
                        transform: "translate(0, -50%)",
                        width: "100%",
                        maxWidth: "470px",
                    } },
                    React.createElement("h2", { className: "heading-2 text-left text-always-white" }, (_a = props.banner) === null || _a === void 0 ? void 0 : _a.title),
                    React.createElement("p", { className: "font-16 pt-16 pb-24 text-left text-always-white" }, (_b = props.banner) === null || _b === void 0 ? void 0 : _b.description),
                    React.createElement(Link, { className: "button-filled", "data-color-mode": "dark", to: (_c = props.banner) === null || _c === void 0 ? void 0 : _c.cta }, (_d = props.banner) === null || _d === void 0 ? void 0 : _d.ctaText)),
                React.createElement("img", { src: isMobile
                        ? (_e = props.banner) === null || _e === void 0 ? void 0 : _e.mobileImage
                        : (_f = props.banner) === null || _f === void 0 ? void 0 : _f.image, style: {
                        width: "100%",
                        lineHeight: 0,
                        objectFit: "cover",
                        height: "100%",
                        objectPosition: "center",
                    } }))))));
}
