// Contains the state which decides which component to load
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { fetchRetailerCodesAndServices } from "../../lib/api";
import RetailersList from "../RetailersList";
import ServicesList from "../ServicesList";
import TacdisIFrame from "../TacdisIFrame";
import { EcomIframe } from "../EcomIframe";
import "./styles.scss";
import { links } from "@volvo-cars/css/links";
var fragment = document.createDocumentFragment();
for (var _i = 0, _a = links(); _i < _a.length; _i++) {
    var link = _a[_i];
    fragment.append(Object.assign(document.createElement("link"), link));
}
document.head.append(fragment);
var ecomRetailers = [
    {
        id: "SE361",
        name: "Ahlberg Bil",
        tenantKey: "fbfac935-e52e-4b41-9a17-e8ec579e9c96",
    },
    {
        id: "SE637",
        name: "Bilbolaget Nord",
        tenantKey: "5a1cdd4b-9fe6-42f2-99dd-cee0927c113c",
    },
    {
        id: "SE557",
        name: "Helmia",
        tenantKey: "3a87b293-33c6-43c8-bbd6-e5323070e94c",
    },
    {
        id: "SE110",
        name: "Bilmånsson",
        tenantKey: "5b3d7176-cc21-48dc-9547-1cbbf5b00436",
    },
    {
        id: "SE113",
        name: "Bildeve",
        tenantKey: "02173f2a-d0fe-4144-9078-0afd077d4c24",
    },
    {
        id: "SE509",
        name: "Rolf Ericson Bil",
        tenantKey: "72cc4180-da47-4dd6-8e85-eda29a32cff7",
    },
    {
        id: "SE625",
        name: "Bilbolaget Personbilar",
        tenantKey: "70aa0c40-5007-43c6-b201-829b17c5f908",
    },
    {
        id: "SE549",
        name: "Bilkompaniet",
        tenantKey: "dee07d6c-6d8f-42e7-85c0-e0654a6a5780",
    },
    {
        id: "SE369",
        name: "Tage Rejmes",
        tenantKey: "529e8ba7-fa05-4d0f-9aa9-0b77930250b9",
    },
    {
        id: "SE113",
        name: "Bildeve",
        tenantKey: "02173f2a-d0fe-4144-9078-0afd077d4c24",
    },
];
export default function (props) {
    var _this = this;
    var _a, _b;
    var apiEndpoint = "https://vcs.gvcs.fbinhouse.se/vcs-tacdis-iframe-injector"; // Default to live service
    if (window.location.host === "localhost") {
        apiEndpoint = ".."; // Default to localhost service
    }
    if (typeof props.apiEndpoint === "string") {
        apiEndpoint = props.apiEndpoint;
    }
    var iframeEndpoint = apiEndpoint;
    if (typeof props.iframeEndpoint === "string") {
        iframeEndpoint = props.iframeEndpoint;
    }
    var _c = useState(null), retailers = _c[0], setRetailers = _c[1];
    var _d = useState(null), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = useState(false), isTacdisDisabled = _e[0], setIsTacdisDisabled = _e[1];
    useEffect(function () {
        if (false) {
            // Set to false when tacdis comes back online.
            setIsTacdisDisabled(true);
        }
        else {
            try {
                (function () { return __awaiter(_this, void 0, void 0, function () {
                    var services, sortedService;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, fetchRetailerCodesAndServices(apiEndpoint)];
                            case 1:
                                services = (_a.sent());
                                sortedService = services.sort(function (a, b) {
                                    return a.branchName.localeCompare(b.branchName);
                                });
                                setRetailers(sortedService);
                                return [2 /*return*/];
                        }
                    });
                }); })();
            }
            catch (e) {
                setErrorMessage(e.message);
            }
            if (typeof props.preSelectRetailer === "string") {
                // console.log("Setting the retailer id to: " + props.preSelectRetailer);
                setSelectedRetailerId(props.preSelectRetailer);
            }
            if (typeof props.preSelectService === "string") {
                if (["wc", "sb", "wr"].includes(props.preSelectService)) {
                    // Runtime validation is necessary before casting in typescript
                    setSelectedServiceId(props.preSelectService);
                }
                else {
                    setErrorMessage("Invalid service: " + props.preSelectService);
                }
            }
        }
    }, []);
    var _f = useState(null), selectedRetailerId = _f[0], setSelectedRetailerId = _f[1];
    var _g = useState(null), selectedRetailer = _g[0], setSelectedRetailer = _g[1];
    useEffect(function () {
        if (retailers === null) {
            setSelectedRetailer(null);
        }
        else {
            var found = retailers.find(function (r) { return r.id === selectedRetailerId; });
            if (found) {
                setSelectedRetailer(found);
            }
            else {
                setSelectedRetailer(null);
            }
        }
    }, [retailers, selectedRetailerId]);
    var _h = useState(null), selectedServiceId = _h[0], setSelectedServiceId = _h[1];
    useEffect(function () {
        /*
        console.log("---");
        console.log("props.preSelectRetailer", props.preSelectRetailer);
        console.log("selectedRetailerId", selectedRetailerId, typeof selectedRetailerId);
        console.log("selectedRetailer", selectedRetailer);
        console.log("Service id", selectedServiceId);
        */
    }, [
        props.preSelectRetailer,
        selectedRetailer,
        selectedRetailerId,
        selectedServiceId,
    ]);
    var displayPage;
    displayPage = "retailers";
    if (selectedRetailer === null) {
        displayPage = "retailers";
    }
    else if (selectedServiceId === null) {
        displayPage = "services";
    }
    else {
        displayPage = "iframe";
    }
    return (React.createElement("div", { className: "app-container pb-64" }, isTacdisDisabled ? (React.createElement("div", { className: "tacdisDisabled" },
        React.createElement("h2", null,
            React.createElement("strong", null, "!"),
            " Ej m\u00F6jligt att boka digitalt"),
        React.createElement("p", null,
            "Tyv\u00E4rr kan vi inte erbjuda en digital bokning f\u00F6r din bil just nu. ",
            React.createElement("br", null),
            "V\u00E4nligen kontakta verkstaden."))) : errorMessage !== null ? (React.createElement("p", null,
        "Error: ",
        errorMessage)) : retailers === null ? (React.createElement("div", { className: "loading" },
        React.createElement("progress", { "aria-label": "Loading", className: "spinner " }),
        React.createElement("p", { className: "font-medium" }, "Laddar..."))) : (React.createElement(React.Fragment, null,
        displayPage === "retailers" && (React.createElement(RetailersList, { retailers: retailers, title: "Boka tid f\u00F6r glasreparation", description: "V\u00E4lj din n\u00E4rmaste Volvohandlare f\u00F6r att boka ett verkstadsbes\u00F6k.", image: "https://dam-stage.fbinhouse.se/o/static/cde2152c-cab9-4161-ac11-ccf1faec352b/thumbnail.jpg", mobileImage: "https://dam-stage.fbinhouse.se/o/static/2db67b05-b072-40b2-a219-6ca77329799d/thumbnail.jpg", imagePosition: "left top", type: "windowrepair", onSelectRetailer: function (id) {
                setSelectedRetailerId(id);
            }, banner: {
                title: "Din närmaste serviceverkstad",
                description: "Volvo Originalservice ger dig en omfattande kontroll av bilen, servicegaranti, vägassistans och originaldelar. Boka en tid online och få hjälp hos din auktoriserade Volvoverkstad. Vi är experter på din bil och vet precis vad du behöver.",
                cta: "/",
                ctaText: "Boka service",
                image: "https://dam-stage.fbinhouse.se/o/static/017494a1-6371-423e-84e7-336bd57db06f/thumbnail.jpg?source=picker",
                mobileImage: "https://dam-stage.fbinhouse.se/o/static/164f3885-c730-42f8-a858-e90efeb37052/thumbnail.jpg",
            } })),
        displayPage === "services" && (React.createElement(ServicesList, { retailer: selectedRetailer, onSelectService: function (id) {
                // console.log("Setting service id from serviceslist", id);
                setSelectedServiceId(id);
            } })),
        displayPage === "iframe" &&
            (ecomRetailers.some(function (retailer) { return retailer.id === (selectedRetailer === null || selectedRetailer === void 0 ? void 0 : selectedRetailer.id); }) ? (
            // This is for retailers with the newer ecom module
            // Only available for window at the moment
            React.createElement(EcomIframe, { serviceType: "Glasreparation", retailerName: ((_a = ecomRetailers.find(function (retailer) {
                    return retailer.id === (selectedRetailer === null || selectedRetailer === void 0 ? void 0 : selectedRetailer.id);
                })) === null || _a === void 0 ? void 0 : _a.name) || "", tenantKey: ((_b = ecomRetailers.find(function (retailer) {
                    return retailer.id === (selectedRetailer === null || selectedRetailer === void 0 ? void 0 : selectedRetailer.id);
                })) === null || _b === void 0 ? void 0 : _b.tenantKey) || "", onBack: function () {
                    setSelectedServiceId(null);
                    setSelectedRetailer(null);
                    setSelectedRetailerId(null);
                } })) : (React.createElement(TacdisIFrame, { endpoint: iframeEndpoint, retailer: selectedRetailer, serviceId: "wr", onBack: function () {
                    setSelectedServiceId(null);
                    setSelectedRetailer(null);
                    setSelectedRetailerId(null);
                }, serviceType: "Glasreparation" })))))));
}
