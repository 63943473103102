import React, { useEffect } from "react";
import { Icon } from "@volvo-cars/react-icons";
export var EcomIframe = function (_a) {
    var tenantKey = _a.tenantKey, retailerName = _a.retailerName, serviceType = _a.serviceType, onBack = _a.onBack;
    useEffect(function () {
        if (!document.querySelector('script[src="https://ecom-cdn.tacdis.com/ecom-retailer-booking/production/js/ecom-retailer-booking.umd.js"]')) {
            var script = document.createElement("script");
            script.src =
                "https://ecom-cdn.tacdis.com/ecom-retailer-booking/production/js/ecom-retailer-booking.umd.js";
            script.type = "text/javascript";
            script.async = true;
            document.head.appendChild(script);
        }
    }, []);
    return (React.createElement("div", { className: "ecom-module flex flex-col items-center" },
        React.createElement("div", { style: { backgroundColor: "#fafafa" }, className: "w-full pt-64 pb-64 text-center" },
            React.createElement("h1", { className: "heading-1 text-secondary" }, retailerName)),
        React.createElement("div", { style: {
                borderBottom: "1px solid #0000001F",
                borderTop: "1px solid #0000001F",
            }, className: "w-full" },
            React.createElement("div", { className: "pl-24 pt-16 pb-16", style: {
                    maxWidth: "calc(1400px + 58px)",
                    marginInline: "auto",
                } },
                React.createElement("div", { className: "flex flex-col md:flex-row items-start md:items-center justify-start gap-8" },
                    React.createElement("div", { className: "flex gap-8" },
                        React.createElement(Icon, { icon: "arrow-back", color: "secondary", size: 24, alt: "Tillbaka till listan" }),
                        React.createElement("p", { className: "font-medium mr-32", style: {
                                color: "#00000070",
                                cursor: "pointer",
                            }, onClick: function () {
                                onBack();
                            } }, "Tillbaka till listan")),
                    React.createElement("p", { className: "font-medium text-secondary hidden md:block" },
                        serviceType,
                        " hos ",
                        retailerName)))),
        React.createElement("div", { className: "flex justify-center" },
            React.createElement("ecom-retailer-booking", { services: "windowrepair", apikey: tenantKey }))));
};
